import { Button, ButtonProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const ZoomButton: React.FC<ButtonProps> = ({ children, sx, ...props }) => {
    const ref = useRef<HTMLButtonElement | null>(null);

    const [width, setWidth] = useState<number | undefined>();
    const [height, setHeight] = useState<number | undefined>();
    const [fontSize, setFontSize] = useState<string | null | undefined>();

    const sxFontSize = (() => {
        if (!fontSize) return;

        return `${Number(fontSize.replace("px", "")) + 1}px`;
    })();

    useEffect(() => {
        if (!ref.current) return;

        const fs = window.getComputedStyle(ref.current).fontSize;
        if (!fs) return;

        setFontSize((current) => {
            if (current) {
                return current;
            } else {
                return fs;
            }
        });
    }, [ref.current?.offsetWidth]);

    useEffect(() => {
        if (!ref.current?.offsetWidth) return;

        setWidth((current) => {
            if (current) {
                return current;
            } else {
                return ref.current?.offsetWidth;
            }
        });
    }, [ref.current?.offsetWidth]);

    useEffect(() => {
        if (!ref.current?.offsetHeight) return;

        setHeight((current) => {
            if (current) {
                return current;
            } else {
                return ref.current?.offsetHeight;
            }
        });
    }, [ref.current?.offsetHeight]);

    return (
        <Button
            ref={ref}
            {...props}
            sx={{
                overflow: "hidden",
                display: "inline-block",
                whiteSpace: "nowrap",
                transition: "all 0.2s ease",
                textAlign: "center",
                width,
                height,
                boxShadow: 0,
                "&:hover": {
                    fontSize: sxFontSize,
                    paddingLeft: 0,
                    paddingRight: 0,
                },
                ...sx,
            }}
        >
            {children}
        </Button>
    );
};

export default ZoomButton;
