import * as Sentry from "@sentry/react";
import { notify } from "app/frontend/utils/notifications";

export const onBoundaryError = (_error: unknown) => {
    console.warn("React/Sentry Error Boundary Handler - onBoundaryError");

    const error = _error as Error;
    if (
        error?.message?.includes("Failed to fetch dynamically imported module") ||
        error?.message?.includes("Importing a module script failed")
    ) {
        window.location.reload();
        return;
    }

    if (error?.message?.toLowerCase()?.includes("operation is insecure")) {
        notify({
            level: "warning",
            title: "ConEd PowerReady requires access to cookies.",
            message: "ConEd PowerReady requires cookies be enabled in order for certain features to function properly.",
        });
        return;
    }

    Sentry.captureException(error, {
        extra: { powerready: "Error occurred in application ErrorBoundary.onBoundaryError" },
    });

    try {
        document.cookie = "";
    } catch (e) {
        console.warn(e);
    }

    try {
        sessionStorage?.clear();
    } catch (e) {
        console.warn(e);
    }

    try {
        localStorage?.clear();
    } catch (e) {
        console.warn(e);
    }

    if ("caches" in window) {
        caches
            .keys()
            .then((names) => names.forEach(async (name) => await caches.delete(name)))
            .catch(console.error);
    }
};
