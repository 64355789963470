/** @format */

import { IS_PRODUCTION } from "app/frontend/constants";

declare global {
    interface Array<T> {
        first(): T;
        last(): T;
    }
}

if (IS_PRODUCTION) {
    // window.console.error = Sentry.captureException;
}

export const DEFAULT_ERROR_MESSAGE = "Please refresh the page and try again.";

if (!Array.prototype.first) {
    Array.prototype.first = function (defaultValue?: any) {
        return this[0] || defaultValue;
    };
}

if (!Array.prototype.last) {
    Array.prototype.last = function (defaultValue?: any) {
        return this[this.length - 1] || defaultValue;
    };
}

export const noop = () => null;

export const isNumber = (value: any) => typeof value === "number" && isFinite(value);

export const isNumeric = (string: string | number | null | undefined): boolean => {
    if (typeof string === "number") {
        return true;
    }

    if (!string) return false;

    if (typeof string !== "string") {
        return false;
    }

    return !!/\d+/.exec(string);
};

export const flatten = (arrays: any[]): any[] => {
    return [].concat(...arrays);
};

const UPCASE_ALWAYS = ["url"];
export const titleize = (string: string, separator = " ", joiner = " ") => {
    if (!string) return "";

    const words = string.toLowerCase().split(separator);

    const toJoin = [];
    for (const word of words) {
        if (word) {
            if (UPCASE_ALWAYS.includes(word)) {
                toJoin.push(word.toUpperCase());
            } else {
                toJoin.push(word[0].toUpperCase() + word.substring(1));
            }
        }
    }
    return toJoin.join(joiner);
};

// https://stackoverflow.com/a/9229821/6410635
export const uniqBy = <T>(array: T[], key: KeyOf<T>) => {
    const seen = new Set();
    return array.filter((item) => {
        const k = item[key];
        return seen.has(k) ? false : seen.add(k);
    });
};

export * from "./logging";
