import { AppBar, Container, Grid2, Link, Toolbar, Typography, useTheme } from "@mui/material";

const pages = [
    [
        {
            name: "Con Edison Privacy Policy",
            route: "https://www.coned.com/en/conedison-privacy-statement",
        },
        {
            name: "Terms of Service",
            route: "https://www.coned.com/en/terms-and-conditions",
        },
    ],
    [
        {
            name: "Accessibility Policy",
            route: "https://www.conedison.com/en/accessibility-policy",
        },
        {
            name: "HomeNet",
            route: "https://cevo.coned.com/",
        },
    ],
    [
        {
            name: "© 2016 - 2024 Consolidated Edison Company of New York, Inc. All Rights Reserved.",
            route: "https://coned.com",
        },
    ],
];

const PageFooter = () => {
    const theme = useTheme();

    return (
        <AppBar
            component={"footer"}
            style={{
                position: "static",
                backgroundColor: "black",
                color: "white",
                boxShadow: "none",
            }}
        >
            <Toolbar disableGutters>
                <Container
                    sx={{
                        display: { xs: "none", sm: "flex" },
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "unset",
                        paddingY: theme.spacing(5),
                    }}
                >
                    {pages.flat().map((page, i) => (
                        <Link
                            key={`${page.route}-${i}`}
                            href={page.route}
                            underline="hover"
                            color={"white"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Typography variant="caption" fontWeight={700}>
                                {page.name}
                            </Typography>
                        </Link>
                    ))}
                </Container>
                <Grid2
                    container
                    direction={"row"}
                    sx={{
                        display: { xs: "flex", sm: "none" },
                    }}
                >
                    {pages.map((page, i) => (
                        <Grid2
                            key={i}
                            size={i === pages.length - 1 ? 12 : 6}
                            sx={{ display: "flex", flexDirection: "column", padding: theme.spacing(3) }}
                        >
                            {page.map((p, j) => (
                                <Link
                                    key={`${p.route}-${j}`}
                                    href={p.route}
                                    underline="hover"
                                    color={"white"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Typography variant="caption" fontWeight={700}>
                                        {p.name}
                                    </Typography>
                                </Link>
                            ))}
                        </Grid2>
                    ))}
                </Grid2>
            </Toolbar>
        </AppBar>
    );
};

export default PageFooter;
