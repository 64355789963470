import { ExpandMore, Facebook, Instagram, LinkedIn, Twitter, YouTube } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@mui/material";
import LinkButton from "app/frontend/components/LinkButton";
import {
    CON_EDISON_ELECTRIC_VEHICLES_POWERREADY_URL,
    CON_EDISON_ELECTRIC_VEHICLES_URL,
    CON_EDISON_HOSTING_MAP_URL,
    CON_EDISON_URL,
} from "app/frontend/constants/external_urls";
import { ROUTES } from "app/frontend/constants/routes";
import { toId } from "app/frontend/utils/inputs";
import { Fragment } from "react/jsx-runtime";

const FOOTER_ITEM_CONTAINER_SX = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mb: 1,
    marginX: 0,
};

const pages = [
    {
        title: "PowerReady Resources",
        routes: [
            {
                name: "Incentive Estimator",
                route: ROUTES.PowerReady,
            },
            {
                name: "Resource Center",
                route: ROUTES.ResourceCenter,
            },
            {
                name: "DAC Screening",
                route: ROUTES.DacScreenerPage,
            },
            {
                name: "Contact Us",
                route: ROUTES.Contact,
            },
        ],
    },
    {
        title: "Con Edison",
        routes: [
            {
                name: "Con Edison of New York",
                route: CON_EDISON_URL,
            },
            {
                name: "Electric Vehicles",
                route: CON_EDISON_ELECTRIC_VEHICLES_URL,
            },
            {
                name: "PowerReady Electric Vehicle Program",
                route: CON_EDISON_ELECTRIC_VEHICLES_POWERREADY_URL,
            },
            {
                name: "Con Edison Hosting Capacity",
                route: CON_EDISON_HOSTING_MAP_URL,
            },
        ],
    },
    {
        title: "Social",
        routes: [
            {
                name: (
                    <Box sx={FOOTER_ITEM_CONTAINER_SX}>
                        <Box sx={{ maxHeight: 50 }}>
                            <Facebook
                                sx={{
                                    maxHeight: "100%",
                                    fontSize: 40,
                                    color: "white",
                                    backgroundColor: "black",
                                    padding: 1.1,
                                    borderRadius: "50%",
                                }}
                            />
                        </Box>
                        &nbsp; FACEBOOK
                    </Box>
                ),
                route: "https://www.facebook.com/ConEdison",
            },
            {
                name: (
                    <Box sx={FOOTER_ITEM_CONTAINER_SX}>
                        <Box sx={{ maxHeight: 50 }}>
                            <Twitter
                                sx={{
                                    maxHeight: "100%",
                                    fontSize: 40,
                                    color: "white",
                                    backgroundColor: "black",
                                    padding: 1.1,
                                    borderRadius: "50%",
                                }}
                            />
                        </Box>
                        &nbsp; X (TWITTER)
                    </Box>
                ),
                route: "https://x.com/conedison",
            },
            {
                name: (
                    <Box sx={FOOTER_ITEM_CONTAINER_SX}>
                        <Box sx={{ maxHeight: 50 }}>
                            <YouTube
                                sx={{
                                    maxHeight: "100%",
                                    fontSize: 40,
                                    color: "white",
                                    backgroundColor: "black",
                                    padding: 1.1,
                                    borderRadius: "50%",
                                }}
                            />
                        </Box>
                        &nbsp; YOUTUBE
                    </Box>
                ),
                route: "https://www.youtube.com/user/ConEdisonNY",
            },
            {
                name: (
                    <Box sx={FOOTER_ITEM_CONTAINER_SX}>
                        <Box sx={{ maxHeight: 50 }}>
                            <LinkedIn
                                sx={{
                                    maxHeight: "100%",
                                    fontSize: 40,
                                    color: "white",
                                    backgroundColor: "black",
                                    padding: 1.1,
                                    borderRadius: "50%",
                                }}
                            />
                        </Box>
                        &nbsp; LINKEDIN
                    </Box>
                ),
                route: "https://www.linkedin.com/company/con-edison/",
            },
            {
                name: (
                    <Box sx={FOOTER_ITEM_CONTAINER_SX}>
                        <Box sx={{ maxHeight: 50 }}>
                            <Instagram
                                sx={{
                                    maxHeight: "100%",
                                    fontSize: 40,
                                    color: "white",
                                    backgroundColor: "black",
                                    padding: 1.1,
                                    borderRadius: "50%",
                                }}
                            />
                        </Box>
                        &nbsp; INSTAGRAM
                    </Box>
                ),
                route: "https://www.instagram.com/conedison/?hl=en",
            },
        ],
    },
];

const ContentFooter = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: "transparent",
                display: "flex",
                [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    // marginX: 2,
                },
                [theme.breakpoints.up("sm")]: {
                    marginY: 15,
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                },
            }}
        >
            {pages.map((page, i) => {
                return (
                    <Fragment key={page.title}>
                        <Box
                            sx={{
                                display: { xs: "none", sm: "flex" },
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography variant="h4" component={"p"} sx={{ mb: 3 }}>
                                {page.title}
                            </Typography>
                            {page.routes.map((route, j) => {
                                return <ContentFooterLink key={`${route.route}-${j}`} route={route} />;
                            })}
                        </Box>
                        <Accordion
                            sx={{
                                display: { xs: undefined, sm: "none" },
                                minWidth: "100vw",
                                backgroundColor: "transparent",
                                borderTop: "1px solid black",
                                borderBottom: i === pages.length - 1 ? "1px solid black" : "",
                                borderRadius: 0,
                                boxShadow: "none",
                            }}
                            style={{ borderRadius: 0 }}
                        >
                            <AccordionSummary
                                aria-controls={toId(`${page.title}-content`)}
                                id={toId(`${page.title}-header`)}
                                expandIcon={<ExpandMore />}
                                sx={{
                                    fontweight: 800,
                                }}
                            >
                                {page.title}
                            </AccordionSummary>
                            <AccordionDetails>
                                {page.routes.map((route, j) => {
                                    return <ContentFooterLink key={`${route.route}-${j}`} route={route} />;
                                })}
                            </AccordionDetails>
                        </Accordion>
                    </Fragment>
                );
            })}
        </Box>
    );
};

const ContentFooterLink: React.FC<{ route: { route: string; name: string | React.JSX.Element } }> = ({ route }) => {
    const theme = useTheme();
    return (
        <LinkButton
            href={route.route}
            button={{
                sx: {
                    backgroundColor: "transparent",
                    marginY: typeof route.name === "string" ? 1 : undefined,
                    display: "block",
                    textDecoration: "none",
                    textAlign: "left",
                },
            }}
        >
            <Typography
                component={"span"}
                variant="caption"
                color="black"
                sx={{
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "initial",
                    },
                    [theme.breakpoints.up("sm")]: {
                        fontSize: 14,
                    },
                    "&:hover": {
                        textDecoration: "underline",
                    },
                    textTransform: "none",
                }}
            >
                {route.name}
            </Typography>
        </LinkButton>
    );
};

export default ContentFooter;
