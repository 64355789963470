import { EProjectTypes } from "app/frontend/constants/enums";

export const ROUTE_PREFIX = "/powerready";
export const ADMIN_ROUTE_PREFIX = "/admin";
export const BUSINESS_DEVELOPMENT_ROUTE_PREFIX = "/bd";

export const ROUTES = {
    PowerReady: ROUTE_PREFIX,
    Projects: {
        DCFC: `${ROUTE_PREFIX}/projects/dcfc`,
        L2: `${ROUTE_PREFIX}/projects/L2`,
        MICROMOBILITY: `${ROUTE_PREFIX}/projects/micromobility`,
    },
    ResourceCenter: `${ROUTE_PREFIX}/resource-center`,
    Contact: `${ROUTE_PREFIX}/contact-us`,
    ThankYou: `${ROUTE_PREFIX}/confirmation`,
    DacScreenerModal: `${ROUTE_PREFIX}/dac-lmi-screener`,
    DacScreenerPage: `${ROUTE_PREFIX}/screener`,
    DacScreenerPageResult: `${ROUTE_PREFIX}/screener/result`,
    LmiEligibility: `${ROUTE_PREFIX}/lmi-eligibility`,
    BD: {
        ContactSubmission: `${BUSINESS_DEVELOPMENT_ROUTE_PREFIX}/contact_submission`,
        ContactSubmissionUpdate: (token = ":token") =>
            `${BUSINESS_DEVELOPMENT_ROUTE_PREFIX}/contact_submission/${token}`,
        ContactSubmissionTokenRefresh: (token = ":token") =>
            `${BUSINESS_DEVELOPMENT_ROUTE_PREFIX}/contact_submission/${token}/refresh`,
    },
    Admin: {
        Root: `${ADMIN_ROUTE_PREFIX}`,
        Banners: `${ADMIN_ROUTE_PREFIX}/banners`,
        Banner: (id: string | number = "new") => `${ADMIN_ROUTE_PREFIX}/banners/${id}`,
        BannerEdit: (id: string | number = 0) => `${ADMIN_ROUTE_PREFIX}/banners/${id}/edit`,
        BannerDelete: (id: string | number = 0) => `${ADMIN_ROUTE_PREFIX}/banners/destroy/${id}`,
        Admins: `${ADMIN_ROUTE_PREFIX}/admins`,
        Admin: (id: string | number = "new") => `${ADMIN_ROUTE_PREFIX}/admins/${id}`,
        AdminEdit: (id: string | number = 0) => `${ADMIN_ROUTE_PREFIX}/admins/${id}/edit`,
        AdminDelete: (id: string | number = 0) => `${ADMIN_ROUTE_PREFIX}/admins/destroy/${id}`,
        ContactSubmissions: {
            INDEX: `${ADMIN_ROUTE_PREFIX}/admin_contact_submissions`,
            SHOW: (id: string | number = 0) => `${ADMIN_ROUTE_PREFIX}/admin_contact_submissions/${id}`,
            UPDATE: (id: string | number = 0) => `${ADMIN_ROUTE_PREFIX}/admin_contact_submissions/${id}`,
        },
        Incentives: `${ADMIN_ROUTE_PREFIX}/incentive_groups`,
        IncentiveTiers: `${ADMIN_ROUTE_PREFIX}/incentive_tiers`,
        IncentiveEdit: (id: string | number) => `${ADMIN_ROUTE_PREFIX}/incentive_tiers/${id}/edit`,
        IncentiveUpdate: (id: string | number) => `${ADMIN_ROUTE_PREFIX}/incentive_tiers/${id}`,
        Contractors: `${ADMIN_ROUTE_PREFIX}/contractors`,
        ContractorEdit: (id: string | number = "new") => `${ADMIN_ROUTE_PREFIX}/contractors/${id}/edit`,
        SignOut: `${ADMIN_ROUTE_PREFIX}/sign_out`,
        Invite: {
            Create: `${ADMIN_ROUTE_PREFIX}/invitation`,
        },
        Password: {
            Create: `${ADMIN_ROUTE_PREFIX}/password`,
        },
    },
};

export const ROUTES_BY_INCENTIVE_TYPE = {
    [EProjectTypes.ALL]: ROUTES.Projects.L2,
    [EProjectTypes.L2]: ROUTES.Projects.L2,
    [EProjectTypes.DCFC]: ROUTES.Projects.DCFC,
    [EProjectTypes.MICROMOBILITY]: ROUTES.Projects.MICROMOBILITY,
};
