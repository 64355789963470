import { IS_NOT_PRODUCTION } from "../../constants";

export const logDev = (...args: any[]) => {
    if (IS_NOT_PRODUCTION) {
        const [message, ...extra] = args;

        if (message instanceof DOMException) {
            console.error(message);
        } else if (typeof message === "string") {
            console.log(`(dev) ${message}`, ...extra);
        } else {
            console.log("(dev)", message, ...extra);
        }
    }
};
