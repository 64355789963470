import { Button, ButtonProps } from "@mui/material";
import ContactIcon from "app/frontend/assets/icons/icn-contact.svg?react";
import ZoomButton from "app/frontend/components/buttons/ZoomButton";
import { useContactUs } from "app/frontend/hooks/navigation/useContactUs";

interface IProps extends ButtonProps {
    isIcon: boolean;
}

const ContactUsButton: React.FC<IProps> = ({ isIcon, ...props }) => {
    const navigateContactUs = useContactUs();

    return isIcon ? (
        <Button
            title="Contact Us"
            onClick={navigateContactUs}
            variant="contained"
            sx={{
                borderRadius: 0,
                boxShadow: 0,
                fontWeight: "bold",
            }}
            {...props}
        >
            <ContactIcon title="Contact Us" style={props.style} />
        </Button>
    ) : (
        <ZoomButton
            onClick={navigateContactUs}
            variant="contained"
            sx={{
                borderRadius: 10,
                boxShadow: 0,
                margin: 2,
                paddingX: 4,
                paddingY: 2,
                fontWeight: "bold",
                fontSize: "14px",
            }}
            {...props}
        >
            Contact Us
        </ZoomButton>
    );
};

export default ContactUsButton;
