import { router } from "@inertiajs/react";
import { AppBar, Avatar, Box, Button, Container, Toolbar, Tooltip, Typography, useTheme } from "@mui/material";
import Coned from "app/frontend/assets/images/coned.svg?react";
import LinkButton from "app/frontend/components/LinkButton";
import { ROUTES } from "app/frontend/constants/routes";
import { useCurrentAdminEmail } from "app/frontend/hooks/admin/useCurrentAdminEmail";
import { FLEX_ROW_SX } from "app/frontend/styles/sx";
import { useCallback, useMemo } from "react";

interface IProps {
    pages: powerready.layout.IAdminHeaderPages;
    headerType: powerready.layout.THeaderType;
    role: "staff" | "admin" | "superadmin";
}

const NO_STAFF_ROUTES = [ROUTES.Admin.ContactSubmissions.INDEX, ROUTES.Admin.Admins];

const AdminHeader: React.FC<IProps> = ({ pages: _pages, headerType, role }) => {
    const pages = useMemo(() => _pages[headerType], [_pages, headerType]);
    const theme = useTheme();
    const email = useCurrentAdminEmail();

    const handleNavigate = useCallback((route: string) => {
        router.visit(route);
    }, []);

    const selectedRoute = typeof window === "undefined" ? undefined : window.location.pathname;

    const routeSelected = useCallback(
        (page: powerready.layout.ILayoutHeaderPage) =>
            selectedRoute?.startsWith(page.routes.main) ||
            page.routes.aliases.some((r) => selectedRoute?.startsWith(r)),
        [selectedRoute],
    );

    const buttonDesktopPageSX = useCallback(
        (page: powerready.layout.ILayoutHeaderPage) => ({
            color: "white",
            backgroundColor: routeSelected(page) ? theme.palette.primary.dark : theme.palette.primary.main,
            border: "none",
            boxShadow: "none",
            mr: 1,
        }),
        [theme, routeSelected],
    );

    return (
        <AppBar
            id="app-header"
            position="sticky"
            sx={{
                backgroundColor: theme.palette.primary.main,
                boxShadow: "none",
                minWidth: "100%",
                top: 0,
                zIndex: 999,
                overflow: "hidden",
                paddingY: 1,
            }}
        >
            <Container maxWidth="xl">
                <Toolbar
                    disableGutters
                    sx={{
                        ...FLEX_ROW_SX,
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <LinkButton href={ROUTES.Admin.Root} button={{ sx: { marginRight: 5 } }}>
                            <Coned title="The Con Edison Logo" fill={"white"} />
                        </LinkButton>

                        {pages.map((page) =>
                            NO_STAFF_ROUTES.includes(page.routes.main) && role === "staff" ? null : (
                                <Button
                                    key={page.routes.main}
                                    variant="contained"
                                    size="large"
                                    onClick={() => handleNavigate(page.routes.main)}
                                    sx={buttonDesktopPageSX(page)}
                                >
                                    {page.name}
                                </Button>
                            ),
                        )}
                    </Box>
                    <Box sx={{ ...FLEX_ROW_SX, alignItems: "center" }}>
                        <LinkButton
                            button={{
                                variant: "contained",
                                size: "large",
                                sx: {
                                    color: "white",
                                    boxShadow: "none",
                                    border: "none",
                                    paddingX: 2,
                                    paddingY: 1,
                                    textDecoration: "none",
                                },
                            }}
                            href={ROUTES.Admin.SignOut}
                        >
                            <Tooltip title={email}>
                                <Typography variant="button">Sign Out</Typography>
                            </Tooltip>
                        </LinkButton>
                        &nbsp; &nbsp;
                        <Tooltip title={email}>
                            <Avatar />
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default AdminHeader;
