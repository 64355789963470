import * as React from "react";
const SvgConed = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:dc": "http://purl.org/dc/elements/1.1/", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:svg": "http://www.w3.org/2000/svg", xmlns: "http://www.w3.org/2000/svg", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", width: 146.78999, height: 29.879999, id: "svg2", "inkscape:version": "0.47 r22583", "sodipodi:docname": "New document 1", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs4" }, /* @__PURE__ */ React.createElement("inkscape:perspective", { "sodipodi:type": "inkscape:persp3d", "inkscape:vp_x": "0 : 526.18109 : 1", "inkscape:vp_y": "0 : 1000 : 0", "inkscape:vp_z": "744.09448 : 526.18109 : 1", "inkscape:persp3d-origin": "372.04724 : 350.78739 : 1", id: "perspective10" }), /* @__PURE__ */ React.createElement("clipPath", { id: "clipPath2906", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { id: "path2908", d: "M 0,0 540,0 540,648 0,648 0,0 z" })), /* @__PURE__ */ React.createElement("clipPath", { id: "clipPath2852", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { id: "path2854", d: "m 351.328,31.516 162,0 0,56.555 -162,0 0,-56.555 z" })), /* @__PURE__ */ React.createElement("linearGradient", { id: "linearGradient2840", spreadMethod: "pad", gradientTransform: "matrix(0,323.97781,323.97781,0,0.3276367,324.02219)", gradientUnits: "userSpaceOnUse", y2: 0, x2: 1, y1: 0, x1: 0 }, /* @__PURE__ */ React.createElement("stop", { id: "stop2842", offset: 0, style: {
  stopOpacity: 1,
  stopColor: "#ffffff"
} }), /* @__PURE__ */ React.createElement("stop", { id: "stop2844", offset: 1, style: {
  stopOpacity: 1,
  stopColor: "#0092cf"
} })), /* @__PURE__ */ React.createElement("inkscape:perspective", { id: "perspective2824", "inkscape:persp3d-origin": "0.5 : 0.33333333 : 1", "inkscape:vp_z": "1 : 0.5 : 1", "inkscape:vp_y": "0 : 1000 : 0", "inkscape:vp_x": "0 : 0.5 : 1", "sodipodi:type": "inkscape:persp3d" })), /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "base", pagecolor: "#ffffff", bordercolor: "#666666", borderopacity: 1, "inkscape:pageopacity": 0, "inkscape:pageshadow": 2, "inkscape:zoom": 5.9575348, "inkscape:cx": 111.73704, "inkscape:cy": 11.490688, "inkscape:document-units": "px", "inkscape:current-layer": "layer1", showgrid: "false", "inkscape:window-width": 1680, "inkscape:window-height": 1e3, "inkscape:window-x": -8, "inkscape:window-y": -8, "inkscape:window-maximized": 1 }), /* @__PURE__ */ React.createElement("metadata", { id: "metadata7" }, /* @__PURE__ */ React.createElement("rdf:RDF", null, /* @__PURE__ */ React.createElement("cc:Work", { "rdf:about": "" }, /* @__PURE__ */ React.createElement("dc:format", null, "image/svg+xml"), /* @__PURE__ */ React.createElement("dc:type", { "rdf:resource": "http://purl.org/dc/dcmitype/StillImage" }), /* @__PURE__ */ React.createElement("dc:title", null)))), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1", transform: "translate(1037.245,-633.88679)" }, /* @__PURE__ */ React.createElement("path", { id: "path2858", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -986.05963,652.65864 c -0.11125,1.21875 -1.06375,4.61 -5.38625,4.61 -4.4325,0 -5.45,-3.39125 -5.45,-5.8725 0,-3.81375 1.66125,-6.54 5.65,-6.54 1.8625,0 4.92125,0.84125 5.20875,4.52125 l -3.1475,0 c -0.13375,-0.77625 -0.62,-1.99375 -2.06125,-1.90625 -1.95,0 -2.46125,1.95 -2.46125,3.4125 0,1.28625 0.045,3.835 2.395,3.835 1.575,0 2.105,-1.50625 2.105,-2.06 l 3.1475,0 z" }), /* @__PURE__ */ React.createElement("path", { id: "path2862", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -976.37288,651.06177 c 0,1.5075 -0.44375,3.5925 -2.83875,3.5925 -2.39125,0 -2.83625,-2.085 -2.83625,-3.5925 0,-1.5075 0.445,-3.59 2.83625,-3.59 2.395,0 2.83875,2.0825 2.83875,3.59 m 3.19,0 c 0,-4.255 -2.85875,-6.20625 -6.02875,-6.20625 -3.16875,0 -6.0275,1.95125 -6.0275,6.20625 0,4.25625 2.85875,6.2075 6.0275,6.2075 3.17,0 6.02875,-1.95125 6.02875,-6.2075" }), /* @__PURE__ */ React.createElement("path", { id: "path2866", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -961.01425,656.95802 -3.10125,0 0,-7.15875 c 0,-0.865 -0.0462,-2.3275 -2.0175,-2.3275 -1.37625,0 -2.43875,0.93 -2.43875,2.725 l 0,6.76125 -3.1025,0 0,-11.7925 2.97,0 0,1.73 0.0438,0 c 0.42125,-0.71 1.30875,-2.04 3.5475,-2.04 2.30375,0 4.09875,1.35375 4.09875,3.94625 l 0,8.15625 z" }), /* @__PURE__ */ React.createElement("path", { id: "path2870", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -946.84863,643.85814 -8.4475,0 0,3.34625 7.7575,0 0,2.81625 -7.7575,0 0,4.12125 8.8225,0 0,2.81625 -12.07875,0 0,-15.915 11.70375,0 0,2.815 z" }), /* @__PURE__ */ React.createElement("path", { id: "path2874", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -942.17425,651.15139 c 0,-1.8625 0.665,-3.61375 2.6375,-3.61375 2.12875,0 2.66,1.90625 2.66,3.79125 0,1.75 -0.775,3.39 -2.74875,3.39 -1.99375,0 -2.54875,-2.17125 -2.54875,-3.5675 m 8.2675,-10.10875 -3.10125,0 0,5.6525 -0.045,0 c -0.48625,-0.75375 -1.41875,-1.83875 -3.41375,-1.83875 -2.5925,0 -4.8975,1.995 -4.8975,6.07375 0,3.235 1.46125,6.33875 4.96375,6.33875 1.28625,0 2.65875,-0.4675 3.41375,-1.79625 l 0.045,0 0,1.485 3.035,0 0,-15.915 z" }), /* @__PURE__ */ React.createElement("path", { d: "m -928.61,640.88902 -3.10125,0 0,2.88125 3.10125,0 0,-2.88125 z m -3.10125,16.06875 3.10125,0 0,-11.7925 -3.10125,0 0,11.7925 z", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, id: "path2876" }), /* @__PURE__ */ React.createElement("path", { id: "path2880", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -919.63463,648.75677 c -0.13,-0.7525 -0.3975,-1.5525 -2.08125,-1.5525 -1.685,0 -1.905,0.64375 -1.905,1.155 0,1.86125 7.37875,0.50875 7.37875,4.8525 0,3.435 -3.325,4.0575 -5.16125,4.0575 -2.68375,0 -5.58625,-0.93125 -5.67625,-4.07875 l 3.1925,0 c 0,0.9975 0.62,1.72875 2.57125,1.72875 1.595,0 1.97125,-0.7775 1.97125,-1.22 0,-1.97125 -7.31375,-0.4875 -7.31375,-4.8975 0,-1.72875 1.08625,-3.94625 4.76625,-3.94625 2.305,0 5.05125,0.57625 5.29625,3.90125 l -3.03875,0 z" }), /* @__PURE__ */ React.createElement("path", { id: "path2884", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -906.46588,651.06177 c 0,1.5075 -0.4425,3.5925 -2.8375,3.5925 -2.39375,0 -2.83625,-2.085 -2.83625,-3.5925 0,-1.5075 0.4425,-3.59 2.83625,-3.59 2.395,0 2.8375,2.0825 2.8375,3.59 m 3.19125,0 c 0,-4.255 -2.85875,-6.20625 -6.02875,-6.20625 -3.16875,0 -6.0275,1.95125 -6.0275,6.20625 0,4.25625 2.85875,6.2075 6.0275,6.2075 3.17,0 6.02875,-1.95125 6.02875,-6.2075" }), /* @__PURE__ */ React.createElement("path", { id: "path2888", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -891.10713,656.95802 -3.1025,0 0,-7.15875 c 0,-0.865 -0.045,-2.3275 -2.0175,-2.3275 -1.37375,0 -2.4375,0.93 -2.4375,2.725 l 0,6.76125 -3.1025,0 0,-11.7925 2.97,0 0,1.73 0.045,0 c 0.41875,-0.71 1.30625,-2.04 3.54625,-2.04 2.30375,0 4.09875,1.35375 4.09875,3.94625 l 0,8.15625 z" }), /* @__PURE__ */ React.createElement("path", { id: "path2892", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -1002.6534,659.32752 -19.3062,0 c -5.7038,0 -10.3426,-4.64 -10.3426,-10.3425 0,-5.70375 4.6388,-10.3425 10.3426,-10.3425 l 19.3062,0 0,-3.5975 -19.3062,0 c -7.6888,0 -13.9426,6.2525 -13.9426,13.94 0,7.6875 6.2538,13.9425 13.9426,13.9425 l 19.3062,0 0,-3.6 z" }), /* @__PURE__ */ React.createElement("path", { id: "path2896", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -1029.6739,649.01427 c 0,4.41875 3.595,8.015 8.0137,8.015 l 18.98,0 0,-3.59875 -18.98,0 c -2.4337,0 -4.4149,-1.98 -4.4149,-4.41625 0,-2.435 1.9812,-4.41625 4.4149,-4.41625 l 18.98,0 0,-3.59875 -18.98,0 c -4.4187,0 -8.0137,3.59625 -8.0137,8.015" }), /* @__PURE__ */ React.createElement("path", { id: "path2900", style: {
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none"
}, d: "m -1002.6534,647.20227 -19.1263,0 c -0.9924,0 -1.7987,0.805 -1.7987,1.7975 0,0.995 0.8063,1.80125 1.7987,1.80125 l 19.1263,0 0,-3.59875 z" })));
export default SvgConed;
