import { ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface IProps extends ToastOptions {
    level: powerready.TAlertLevel;
    title: string;
    message?: string;
}

export const notify = ({ level, title, message, ...rest }: IProps): string | number => {
    const options = {
        toastId: `${level}-${title}-${message}`,
        position: rest?.position || "top-right",
        closeOnClick: typeof rest.closeOnClick === "boolean" ? rest.closeOnClick : true,
        ...rest,
    } as ToastOptions;

    const notification = message ? `${title} ${message}` : title;

    return toast[level](notification, options);
};
