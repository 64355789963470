import { createTheme } from "@mui/material";

const MOBILE_BREAKPOINT = 768;

export const THEME_COLOR_PALETTE = {
    primary: {
        main: "#0078CF", // use with bg + text, buttons + icons, links, borders + dividers
        dark: "#005AA3",
        light: "#069BD7", // ConEd brand color - use with logos, accent bars, navigation states
    },
    secondary: {
        main: "#636363",
        light: "#E6E6E6",
        dark: "#2A2A2A",
    },
    success: {
        main: "#00882B",
        // light: "#c2f0c2",
        light: "#98e698",
    },
    warning: {
        main: "#FDEE8A",
    },
    error: {
        main: "#D93927",
        dark: "#94000F",
    },
    // white: {
    //     main: "#ffffff",
    // },
};

const POWERREADY_THEME = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                classes: {
                    root: "my-class",
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: MOBILE_BREAKPOINT,
            md: 1000,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: ["Lexend", "sans-serif"].join(","),

        h1: {
            fontSize: "46px",
            fontWeight: 900,
            lineHeight: "60px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)`]: {
                fontSize: "33px",
                fontWeight: 900,
                lineHeight: "43px",
            },
        },
        h2: {
            fontSize: "23px",
            fontWeight: 900,
            lineHeight: "43px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)`]: {
                fontSize: "23px",
                fontWeight: 900,
                lineHeight: "30px",
            },
        },
        h3: {
            fontSize: "20px",
            fontWeight: 900,
            lineHeight: "30px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)`]: {
                fontSize: "23px",
                fontWeight: 900,
                lineHeight: "24px",
            },
        },
        h4: {
            fontSize: "16px",
            fontWeight: 900,
            lineHeight: "18px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)`]: {
                fontSize: "16px",
                fontWeight: 900,
                lineHeight: "18px",
            },
        },
        body1: {
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "27px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)`]: {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "27px",
            },
        },
        body2: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)`]: {
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
            },
        },
        buttonLarge: {
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "24px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)` as string]: {
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
            },
        },
        buttonSmall: {
            fontSize: "14px",
            fontWeight: 900,
            lineHeight: "24px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)` as string]: {
                fontSize: "14px",
                fontWeight: 900,
                lineHeight: "24px",
            },
        },
        chartLabel: {
            fontSize: "14px",
            fontWeight: 500, // semibold, 400 is "normal"
            lineHeight: "21px",
            [`@media (max-width:${MOBILE_BREAKPOINT}px)` as string]: {
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "21px",
            },
        },
    },
    palette: THEME_COLOR_PALETTE,
});

export default POWERREADY_THEME;
