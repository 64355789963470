import { router } from "@inertiajs/react";
import { MenuOutlined } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Button,
    Container,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemText,
    SxProps,
    Theme,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import ContactUsButton from "app/frontend/components/buttons/ContactUsButton";
import LinkButton from "app/frontend/components/LinkButton";
import { ROUTES } from "app/frontend/constants/routes";
import { useCallback, useRef, useState } from "react";

import CloseIcon from "app/frontend/assets/icons/icn-close.svg?react";
import Coned from "app/frontend/assets/images/coned.svg?react";

import HeaderButton from "app/frontend/components/static/header/HeaderButton";
import { noop } from "app/frontend/utils";

interface IPage {
    name: (isSelected: boolean, visibility: "hidden" | "visible") => JSX.Element;
    routes: {
        main: string;
        aliases: string[];
    };
}

const pages: IPage[] = [
    {
        name: (isSelected, visibility) => (
            <HeaderButton title="Incentive Estimator" visibility={visibility} isSelected={isSelected} />
        ),
        routes: {
            main: ROUTES.PowerReady,
            aliases: [],
        },
    },
    {
        name: (isSelected, visibility) => (
            <HeaderButton title="Resource Center" visibility={visibility} isSelected={isSelected} />
        ),
        routes: {
            main: ROUTES.ResourceCenter,
            aliases: [],
        },
    },
    {
        name: (isSelected, visibility) => (
            <HeaderButton title="DAC Screener" visibility={visibility} isSelected={isSelected} />
        ),
        routes: {
            main: ROUTES.DacScreenerPage,
            aliases: [],
        },
    },
    {
        name: (isSelected, visibility) => (
            <HeaderButton title="LMI Eligibility" visibility={visibility} isSelected={isSelected} />
        ),
        routes: {
            main: ROUTES.LmiEligibility,
            aliases: [],
        },
    },
    {
        name: (isSelected, visibility) => (
            <HeaderButton title="Contact Us" visibility={visibility} isSelected={isSelected} />
        ),
        routes: {
            main: ROUTES.Contact,
            aliases: [ROUTES.ThankYou],
        },
    },
];

const Header = () => {
    const theme = useTheme();

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    }, []);

    const handleCloseNavMenu = useCallback(() => {
        setAnchorElNav(null);
    }, []);

    const handleNavigate = useCallback((route: string) => {
        router.visit(route);
    }, []);

    const selectedRoute = typeof window === "undefined" ? undefined : window.location.pathname;

    const routeSelected = useCallback(
        (page?: IPage) =>
            !!(selectedRoute === page?.routes?.main || page?.routes?.aliases?.includes(selectedRoute as string)),
        [selectedRoute],
    );

    const buttonDesktopPageSX = useCallback(
        (page?: IPage) => {
            const isContact = page?.routes.main === ROUTES.Contact;
            const boxShadow = `inset 0 3px 0 ${isContact ? theme.palette.common.white : theme.palette.primary.light}`;

            return {
                color: isContact ? "common.white" : "common.black",
                backgroundColor: !page || isContact ? "primary.main" : "common.white",
                textDecoration: isContact ? "underline" : "none",

                // box shadow as  an "interior" border
                // https://stackoverflow.com/a/18841365/6410635
                boxShadow: routeSelected(page) ? boxShadow : "none",

                borderLeft: page ? "1px solid black" : "1px solid white",
                borderRadius: 0,
                px: 3,
                py: 4,
                minHeight: "100%",
                minWidth: {
                    sm: "15ch",
                    md: "17.5ch",
                    lg: "20ch",
                },

                "&:hover": {
                    boxShadow: page ? boxShadow : "none",
                },
            } as SxProps<Theme>;
        },
        [routeSelected, theme.palette.common.white, theme.palette.primary.light],
    );

    const contactButtonRef = useRef<HTMLButtonElement | null>(null);

    return (
        <AppBar
            id="app-header"
            position="sticky"
            sx={{
                backgroundColor: "white",
                boxShadow: "none",
                borderBottom: `1px solid ${theme.palette.secondary.dark}`,
                minWidth: "100%",
                top: 0,
                zIndex: 999,
                overflow: "hidden",
            }}
        >
            <Container disableGutters sx={{ mx: 0, minWidth: "100%" }}>
                <Toolbar disableGutters sx={{ borderTop: "1px solid black" }}>
                    <Container sx={{ display: "flex", alignSelf: "center" }}>
                        <LinkButton href={ROUTES.PowerReady}>
                            <Coned title="The Con Edison Logo" fill={"#0099d8"} />
                        </LinkButton>
                    </Container>

                    <Box
                        sx={{
                            flexGrow: 1,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            display: { xs: "none", sm: "flex" },
                        }}
                    >
                        {pages
                            .map((page) => (
                                <Button
                                    key={page.routes.main}
                                    ref={page.routes.main === ROUTES.Contact ? contactButtonRef : undefined}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        handleNavigate(page.routes.main);
                                    }}
                                    sx={buttonDesktopPageSX(page)}
                                    size="large"
                                >
                                    <Typography component={"div"} variant="caption" fontWeight={700}>
                                        {page.name(false, page.routes.main === ROUTES.Contact ? "visible" : "hidden")}
                                    </Typography>
                                </Button>
                            ))
                            .concat([
                                <Button
                                    key={"empty-box"}
                                    onClick={noop}
                                    sx={buttonDesktopPageSX()}
                                    style={{ minHeight: contactButtonRef.current?.offsetHeight }}
                                    aria-hidden={true}
                                    disabled
                                    disableRipple
                                    disableFocusRipple
                                    disableTouchRipple
                                >
                                    <Typography
                                        visibility={"hidden"}
                                        component={"div"}
                                        variant="caption"
                                        fontWeight={700}
                                    >
                                        {pages.last().name(false, "hidden")}
                                    </Typography>
                                </Button>,
                            ])}
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", sm: "none" },
                        }}
                    >
                        <ContactUsButton
                            isIcon={true}
                            sx={{ mr: 0.25, borderRadius: 0, py: 2 }}
                            style={{ fill: "white" }}
                        />
                        <Button
                            title="Menu"
                            onClick={handleOpenNavMenu}
                            sx={{ backgroundColor: theme.palette.primary.main, color: "white", borderRadius: 0 }}
                        >
                            <MenuOutlined titleAccess="Open or Close the Menu" width={22} />
                        </Button>
                        <Dialog
                            id="menu-appbar"
                            fullScreen
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "initial", sm: "none" },
                            }}
                        >
                            <Toolbar
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Container disableGutters sx={{ display: { xs: "flex", sm: "none" } }}>
                                    <LinkButton href={ROUTES.PowerReady}>
                                        <Coned title="The Con Edison Logo" fill={"#0099d8"} />
                                    </LinkButton>
                                </Container>
                                <IconButton title="Close" onClick={handleCloseNavMenu} sx={{ p: 0 }}>
                                    <CloseIcon
                                        title="Close"
                                        style={{ fill: theme.palette.primary.main, fontSize: "1em" }}
                                    />
                                </IconButton>
                            </Toolbar>
                            <Box sx={{ overflow: "hidden" }}>
                                <List>
                                    {pages.map((page) => (
                                        <ListItem
                                            key={page.routes.main}
                                            onClick={() => handleNavigate(page.routes.main)}
                                            sx={{
                                                color:
                                                    page.routes.main === ROUTES.Contact
                                                        ? theme.palette.primary.main
                                                        : "black",
                                                textDecoration:
                                                    page.routes.main === ROUTES.Contact ? "underline" : "none",
                                                borderRadius: 0,
                                                marginY: 3,
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography component={"div"} fontWeight={900}>
                                                        {page.name(
                                                            routeSelected(page),
                                                            page.routes.main === ROUTES.Contact ? "visible" : "hidden",
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Dialog>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
