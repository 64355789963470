import { Circle } from "@mui/icons-material";
import { Grid2, Typography, useTheme } from "@mui/material";
import ArrowIcon from "app/frontend/assets/icons/icn-carat.svg?react";
import ContactIcon from "app/frontend/assets/icons/icn-contact.svg?react";
import { useIsMobilePhone } from "app/frontend/hooks/useIsMobilePhone";
import { FLEX_ROW_SX } from "app/frontend/styles/sx";

interface IProps {
    title: string;
    visibility: "hidden" | "visible";
    isSelected: boolean;
}

const HeaderButton: React.FC<IProps> = ({ title, visibility, isSelected }) => {
    const theme = useTheme();
    const isMobilePhone = useIsMobilePhone();

    return (
        <Grid2 container>
            <Grid2 size={11} sx={{ ...FLEX_ROW_SX, alignItems: "center" }}>
                <ContactIcon
                    title="Contact"
                    width={visibility === "hidden" ? "0em" : undefined}
                    style={{
                        fill: isMobilePhone ? theme.palette.primary.light : theme.palette.common.white,
                        verticalAlign: "bottom",
                        marginRight: 5,
                    }}
                />{" "}
                <Typography
                    variant={isMobilePhone ? "h3" : "body2"}
                    component={isMobilePhone ? "h3" : "span"}
                    fontWeight={"bold"}
                    textTransform={"none"}
                >
                    {title}
                </Typography>
                {isSelected && (
                    <Circle
                        sx={{
                            ml: 1,
                            fontSize: "0.7em",
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
            </Grid2>
            <Grid2 size={1} sx={{ display: { xs: undefined, sm: "none" } }}>
                <ArrowIcon width={"1em"} style={{ transform: "rotate(270deg)" }} />
            </Grid2>
        </Grid2>
    );
};

export default HeaderButton;
